<template>
  <base-layout-two
    :page-title="`Care Plan`"
    :page-default-back-link="`/users/${$route.params.id}`"
    end-button-text="Edit"
    :end-button-url="`/users/${$route.params.id}/care_plans/${$route.params.care_plan_id}/edit`"
  >
    <section class="ion-padding background-white">
      <h1>{{ formatDate(care_plan.date, "iiii - MMMM do, yyyy") }}</h1>
    </section>

    <div class="padding-8">
      <base-card title="Pain Management">
        <ion-grid>
          <ion-row>
            <!-- Pain Level -->
            <ion-col size="12" size-lg="6">
              <base-field-display label-text="Level of Pain">
                <p>{{ care_plan.pain_level }}</p>
              </base-field-display>
            </ion-col>

            <!-- Pain Location -->
            <ion-col size="12" size-lg="6">
              <base-field-display label-text="Pain Location">
                <p class="ion-text-capitalize">{{ care_plan.pain_location }}</p>
              </base-field-display>
            </ion-col>

            <!-- Perineal Cold Packs -->
            <ion-col size="12" size-lg="6">
              <base-field-display label-text="Perineal Cold Packs">
                <p class="ion-text-capitalize">
                  {{ perineal_cold_pack }}
                </p>
              </base-field-display>
            </ion-col>

            <!-- Maternal Bleeding -->
            <ion-col size="12" size-lg="6">
              <base-field-display label-text="Maternal Bleeding">
                <p class="ion-text-capitalize">
                  {{ care_plan.maternal_bleeding }}
                </p>
              </base-field-display>
            </ion-col>
          </ion-row>
        </ion-grid>
      </base-card>

      <base-card title="Physical Recovery">
        <ion-grid>
          <ion-row>
            <!-- Sitz Bath -->
            <ion-col size="12" size-lg="6">
              <base-field-display label-text="Sitz Bath?">
                <p class="ion-text-capitalize">
                  {{ care_plan.sitz_bath }}
                </p>
              </base-field-display>
            </ion-col>

            <!-- Foot Bath -->
            <ion-col size="12" size-lg="6">
              <base-field-display label-text="Foot Bath?">
                <p class="ion-text-capitalize">
                  {{ care_plan.foot_bath }}
                </p>
              </base-field-display>
            </ion-col>

            <!-- Lactation Assitance -->
            <ion-col size="12" size-lg="6">
              <base-field-display label-text="Lactation Assistance">
                <p class="ion-text-capitalize">
                  {{ care_plan.lactation_assistance }}
                </p>
              </base-field-display>
            </ion-col>

            <!-- Postnatal Massage -->
            <ion-col size="12" size-lg="6">
              <base-field-display label-text="Postnatal Massage">
                <p class="ion-text-capitalize">
                  {{ care_plan.postnatal_massage }}
                </p>
              </base-field-display>
            </ion-col>
          </ion-row>
        </ion-grid>
      </base-card>

      <base-card title="Breast Pumping & Feeding">
        <ion-grid>
          <ion-row>
            <!-- Breast Feeding -->
            <ion-col size="12" size-lg="6">
              <base-field-display label-text="Breast Feeding">
                <p class="ion-text-capitalize">
                  {{ breast_feeding }}
                </p>
              </base-field-display>
            </ion-col>

            <!-- Pumping -->
            <ion-col size="12" size-lg="6">
              <base-field-display label-text="Pumping?">
                <p class="ion-text-capitalize">
                  {{ pumping }}
                </p>
              </base-field-display>
            </ion-col>
          </ion-row>
        </ion-grid>
      </base-card>

      <base-card title="Education & Guidance">
        <ion-grid>
          <ion-row>
            <!-- General Notes -->
            <ion-col size="12" size-lg="6">
              <base-field-display label-text="Notes">
                <p>{{ care_plan.education }}</p>
              </base-field-display>
            </ion-col>
          </ion-row>
        </ion-grid>
      </base-card>

      <base-card title="Mental Wellness">
        <ion-grid>
          <ion-row>
            <!-- General Notes -->
            <ion-col size="12" size-lg="6">
              <base-field-display label-text="Notes">
                <p>{{ care_plan.mental_wellness }}</p>
              </base-field-display>
            </ion-col>
          </ion-row>
        </ion-grid>
      </base-card>

      <base-card title="General Notes">
        <ion-grid>
          <ion-row>
            <!-- General Notes -->
            <ion-col size="12" size-lg="6">
              <base-field-display label-text="Notes">
                <p>{{ care_plan.general_notes }}</p>
              </base-field-display>
            </ion-col>
          </ion-row>
        </ion-grid>
      </base-card>
    </div>
  </base-layout-two>
</template>

<script>
import { IonGrid, IonRow, IonCol } from "@ionic/vue";
import { formatDate } from "@/util/helpers";

export default {
  components: {
    IonGrid,
    IonRow,
    IonCol,
  },

  data() {
    return {
      user: {},
      care_plan: {
        mother: {},
      },
      formatDate,
    };
  },

  computed: {
    perineal_cold_pack() {
      if (this.care_plan.perineal_cold_pack !== null)
        if (this.care_plan.perineal_cold_pack === 1) return "Yes";
        else if (this.care_plan.perineal_cold_pack === 0) return "No";

      return "";
    },

    breast_feeding() {
      if (this.care_plan.breast_feeding !== null)
        if (this.care_plan.breast_feeding === 1) return "Yes";
        else if (this.care_plan.breast_feeding === 0) return "No";

      return "";
    },

    pumping() {
      if (this.care_plan.pumping !== null)
        if (this.care_plan.pumping === 1) return "Yes";
        else if (this.care_plan.pumping === 0) return "No";

      return "";
    },
  },

  ionViewWillEnter() {
    this.fetchCarePlan();
  },

  methods: {
    async fetchCarePlan() {
      await this.axios
        .get(
          `${process.env.VUE_APP_API}/care_plans/${this.$route.params.care_plan_id}`
        )
        .then((response) => {
          this.care_plan = response.data.care_plan;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>